var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-w-full tw-flex-col lg:"},[_c('div',{staticClass:"tw-flex tw-w-full tw-justify-between tw-items-center"},[_c('icons',{attrs:{"name":"arrow-back"}}),_c('base-button',{attrs:{"button-text":"Add Post"},on:{"click":function($event){return _vm.$router.push({name: 'NewBlogPost'})}}})],1),_c('div',{staticClass:"company-dashboard lg:tw-mt-10 tw-mt-5 tw-p-5"},[_c('div',{staticClass:"tw-flex tw-justify-between tw-w-full tw-items-center tw-flex-wrap"},[_c('h6',{staticClass:"header"},[_vm._v("All Posts")]),_c('div',{staticClass:"mr-2 tw-mt-5 lg:tw-mt-0 ",staticStyle:{"border-radius":"10px","border":"1px solid rgba(79, 79, 79, 0.08)"}},[_c('v-text-field',{staticClass:"p-0",attrs:{"placeholder":"Search for post","solo":"","flat":"","hide-details":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{staticClass:"ml-5",attrs:{"color":_vm.color}},[_vm._v(" mdi-magnify ")])]},proxy:true},{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":_vm.color}},[_vm._v(" mdi-filter-variant ")])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"mt-4 mx-1 table",attrs:{"headers":_vm.headers,"mobile-breakpoint":0,"search":_vm.search,"item-key":"id","single-select":false,"height":"available","hide-default-footer":_vm.blogPosts.length <= 7,"items":_vm.blogPosts,"no-data-text":_vm.not_found,"loading":_vm.loading},scopedSlots:_vm._u([{key:"header.postImage",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Image")])]},proxy:true},{key:"item.postImage",fn:function(ref){
var item = ref.item;
return [(item.postImage)?_c('img',{staticClass:"company-logo",attrs:{"src":item.postImage,"alt":"logo"}}):_c('div',{staticClass:"no-image",style:({ backgroundColor: _vm.color })},[_c('h6',{staticClass:"tw-flex tw-justify-center tw-items-center "},[_vm._v(" "+_vm._s(item.postTitle[0])+" ")])])]}},{key:"header.postTitle",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Title")])]},proxy:true},{key:"item.postTitle",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"td-style"},[_vm._v(_vm._s(item.postTitle))])]}},{key:"header.postContent",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Content")])]},proxy:true},{key:"item.postContent",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"td-style"},[_vm._v(_vm._s(item.postContent.substring(0,20) +"..."))])]}},{key:"header.writtenBy",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Written By")])]},proxy:true},{key:"item.writtenBy",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"td-style"},[_vm._v(_vm._s(item.writtenBy))])]}},{key:"header.status",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Status")])]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"td-style"},[_vm._v(_vm._s(item.status))])]}},{key:"header.dateCreated",fn:function(){return [_c('span',{staticClass:"status"},[_vm._v("Date Created")])]},proxy:true},{key:"item.dateCreated",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"td-style"},[_vm._v(_vm._s(_vm.getDate(item.createdOn)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('td',{staticClass:"d-flex justify-center"},[_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.color}},[_vm._v("mdi-dots-horizontal")])],1)],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{},[_c('v-list-item-title',{staticClass:"tw-flex-row tw-flex tw-cursor-pointer menu-text",staticStyle:{"color":"black"}},[_vm._v(" View ")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.updatePostStatus(item)}}},[_c('v-list-item-title',{staticClass:"tw-flex-row tw-flex tw-cursor-pointer menu-text",staticStyle:{"color":"black"}},[_vm._v(" "+_vm._s(item.status === 'DRAFT' ? 'Publish' : 'Draft')+" ")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.routeToEditPost(item)}}},[_c('v-list-item-title',{staticClass:"tw-flex-row tw-flex tw-cursor-pointer menu-text",staticStyle:{"color":"black"}},[_vm._v(" Edit ")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.handleModal(item)}}},[_c('v-list-item-title',{staticClass:"tw-flex-row tw-flex tw-cursor-pointer menu-text",staticStyle:{"color":"black"}},[_vm._v(" Delete ")])],1)],1)],1)]}}],null,true)})],1)]),_c('modal',{attrs:{"dialog":_vm.showModal,"title":"Delete Post","section":"delete-post","description":'Are you sure, you want to delete post with title: '+_vm.selectedPost.postTitle,"icon":"oops","loading":_vm.modalLoading},on:{"close":_vm.handleModal,"deletePost":function($event){return _vm.deleteBlogPost(_vm.selectedPost)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }