<template>
<div class="tw-flex tw-w-full tw-flex-col lg:">
  <div class="tw-flex tw-w-full tw-justify-between tw-items-center">
    <icons name="arrow-back" />
    <base-button button-text="Add Post" @click="$router.push({name: 'NewBlogPost'})" />
  </div>

  <div class="company-dashboard lg:tw-mt-10 tw-mt-5 tw-p-5">
    <div class="tw-flex tw-justify-between tw-w-full tw-items-center tw-flex-wrap">
      <h6 class="header">All Posts</h6>
      <div
          class="mr-2 tw-mt-5 lg:tw-mt-0 "
          style="border-radius: 10px; border: 1px solid rgba(79, 79, 79, 0.08)"
      >
        <v-text-field
            placeholder="Search for post"
            class="p-0"
            solo
            flat
            hide-details
            v-model="search"
        >
          <template #prepend>
            <v-icon :color="color" class="ml-5">
              mdi-magnify
            </v-icon>
          </template>
          <template #append>
            <v-icon :color="color">
              mdi-filter-variant
            </v-icon>
          </template>
        </v-text-field>
      </div>
      <v-data-table
          :headers="headers"
          :mobile-breakpoint="0"
          :search="search"
          item-key="id"
          :single-select="false"
          height="available"
          :hide-default-footer="blogPosts.length <= 7"
          :items="blogPosts"
          :no-data-text="not_found"
          class="mt-4 mx-1 table"
          :loading="loading"


      >
        <template #header.postImage>
          <span class="status">Image</span>
        </template>
        <template v-slot:[`item.postImage`]="{ item }">
          <img
              :src="item.postImage"
              alt="logo"
              v-if="item.postImage"
              class="company-logo"
          />
          <div
              class="no-image"
              v-else
              :style="{ backgroundColor: color }"

          >
            <h6 class="tw-flex tw-justify-center tw-items-center ">
              {{ item.postTitle[0] }}
            </h6>
          </div>
        </template>
        <template #header.postTitle>
          <span class="status">Title</span>
        </template>
        <template v-slot:[`item.postTitle`]="{ item }">
          <span class="td-style">{{ item.postTitle }}</span>
        </template>

        <template #header.postContent>
          <span class="status">Content</span>
        </template>
        <template v-slot:[`item.postContent`]="{ item }">
          <span class="td-style">{{ item.postContent.substring(0,20) +"..." }}</span>
        </template>
        <template #header.writtenBy>
          <span class="status">Written By</span>
        </template>
        <template v-slot:[`item.writtenBy`]="{ item }">
          <span class="td-style">{{item.writtenBy }}</span>
        </template>
        <template #header.status>
          <span class="status">Status</span>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <span class="td-style">{{item.status }}</span>
        </template>
        <template #header.dateCreated>
          <span class="status">Date Created</span>
        </template>
        <template v-slot:[`item.dateCreated`]="{ item }">
          <span class="td-style">{{getDate(item.createdOn) }}</span>
        </template>
        <template v-slot:[`item.actions`] ="{item}">
          <v-menu bottom offset-y left>
            <template v-slot:activator="{ on, attrs }">
              <td class="d-flex justify-center">
                <v-btn v-bind="attrs" v-on="on" icon>
                  <v-icon :color="color">mdi-dots-horizontal</v-icon>
                </v-btn>
              </td>
            </template>
            <v-list>
              <v-list-item class="" >
                <v-list-item-title
                    style="color: black"
                    class="tw-flex-row tw-flex tw-cursor-pointer menu-text">
                  View
                </v-list-item-title>
              </v-list-item>
              <v-list-item class=""  @click="updatePostStatus(item)">
                <v-list-item-title
                    style="color: black"
                    class="tw-flex-row tw-flex tw-cursor-pointer menu-text">
                  {{item.status === 'DRAFT' ? 'Publish' : 'Draft'}}
                </v-list-item-title>
              </v-list-item>
              <v-list-item class="" @click="routeToEditPost(item)" >
                <v-list-item-title
                    style="color: black"
                    class="tw-flex-row tw-flex tw-cursor-pointer menu-text">
                  Edit
                </v-list-item-title>
              </v-list-item>
              <v-list-item class="" @click="handleModal(item)">
                <v-list-item-title
                    style="color: black"
                    class="tw-flex-row tw-flex tw-cursor-pointer menu-text">
                  Delete
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </div>
  </div>
  <modal :dialog="showModal" title="Delete Post" section="delete-post"
         :description="'Are you sure, you want to delete post with title: '+selectedPost.postTitle" icon="oops"
         @close="handleModal" @deletePost="deleteBlogPost(selectedPost)"
        :loading="modalLoading"/>
</div>
</template>

<script>
import Icons from "@/components/reuseables/Icons";
import {deletePost, getAllBlogPost, toggleBlogPostStatus} from "@/services/api/APIService";
import Modal from "@/components/reuseables/Modal";
import BaseButton from "@/components/reuseables/BaseButton.vue";
import dayjs from "dayjs";
export default {
  name: "AllBlogPostView",
  components: {BaseButton, Modal, Icons},
  data(){
    return{
      color: "#004AAD",
      search : "",
      blogPosts:[],
      not_found : "Blog Post will show here",
      headers: [
        { text: "", value: "postImage" },
        { text: "", value: "postTitle" },
        { text: "", value: "postContent" },
        { text: "", value: "writtenBy" },
        { text: "", value: "status" },
        { text: "", value: "dateCreated" },
        { text: "", value: "actions", align: "end" },
      ],
      loading : false,
      showModal : false,
      modalLoading : false,
      modalTitle: "",
      modalDesc : " ",
      transportCompanyId:null,
      selectedPost:{}

    }
  },

  methods:{
    handleModal(item){
      if (item) {
        this.selectedPost = item
      }
      this.showModal = !this.showModal
    },

   async getAllPost(){
      this.loading = true
      await getAllBlogPost().then(res =>{
        this.blogPosts = res.data
        this.blogPosts.sort(function (a, b) {
          return dayjs(b.createdOn) - dayjs(a.createdOn);
        });
        this.blogPosts.forEach((post, index) => (post.sno = index + 1));
        this.loading = false
      }).catch(err => {
        this.$displaySnackbar({
          message: err.response.data.details[0],
          success: false
        })
        this.loading = false
      })
    },
    async updatePostStatus(item){
      let data = {}
      data.id = item.id
      toggleBlogPostStatus(data).then(()=>{
        this.getAllPost()
      })
    },
    routeToEditPost(item){
      // sessionStorage.setItem("post", JSON.stringify(item))
      this.$store.dispatch("marketing/setSelectedBlogPost",item)
      this.$router.push({name: 'EditBlogPost',params:{title: item.postTitle}})
    },
    async deleteBlogPost(item){
      this.modalLoading = true
      deletePost(item).then(()=>{
        this.getAllPost()
        this.modalLoading = false
        this.showModal = false
      }).catch(err =>{
        this.$displaySnackbar({
          message: err.message,
          success: false,
        })
        this.showModal = false
      }).finally(()=> this.modalLoading = false)
    },
    getDate(date){
      const today = new Date();
      const yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      if (dayjs(date).toDate().toDateString()=== today.toDateString()) {
        return "Today";
      } else if (dayjs(date).toDate().toDateString() === yesterday.toDateString()) {
        return "Yesterday";
      } else {
        return dayjs(date).toDate().toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" });
      }
    },
  },
 async created() {
    await this.getAllPost()
  }
}
</script>

<style scoped lang="scss">
.company-dashboard{
  display: flex;
  background: #FFFFFF;
  width: 100%;
  min-height: 70vh;
  max-height: available;
  border-radius: 10px;
  flex-direction: column;
}
.header{
  font-family: 'Inter', sans-serif ;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #004AAD;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.table{
  width: 100%;
}

.v-data-table__wrapper > table {
  margin-top: 55px !important;
}

.theme--light.v-data-table
> .v-data-table__wrapper
> table
> thead
> tr
> th:first-child,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child {
  padding-left: 16px;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.status {
  font-family: "Poppins",sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #4f4f4f;
  padding: 0 0 0 5px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.td-style {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0;
  text-align: left;
  text-transform: capitalize;

}
.company-logo{
  height: 100px;
  width: 100px;
  border-radius: 10px;
  object-fit: contain;
}

.no-image{
  display: flex;
    height: 32px;
    width: 32px;
    border-radius: 50%;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  text-align: center;
}
</style>